@import "_reset.css";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

:root {
  --text-color: #000;
  --link-color: #cc0000;
  --page-background-color: #fafaef;
  --blockquote-color: #555;
  --footer-text-color: #3c3c3c;

  @media (prefers-color-scheme: dark) {
    --page-background-color: #1a1a1a;
    --text-color: #fafaef;
    --link-color: #ee6666;
    --blockquote-color: #ccc;
    --footer-text-color: #ccc;
  }
}

a {
  color: var(--link-color);
}

code {
  font-size: 1rem;
}

body {
  color: var(--text-color);
  margin: 0 8px;
  font-family: Merriweather, serif;
  background-color: var(--page-background-color);
  line-height: 175%;
}

blockquote {
  border-left: 3px solid #ccc;
  border-start-start-radius: 3px;
  border-end-start-radius: 3px;
  color: var(--blockquote-color);
  font-style: italic;
  margin-left: 1rem;
  padding-left: 1rem;
}

ul {
  padding-left: 1.5rem;
}

body > .page-content {
  max-width: 42rem;
  padding-top: 2rem;
  margin: 0 auto;
}

.posts-list {
  ul {
    padding-left: 1rem;
  }
}

h2,
h3,
h4,
h5 {
  margin-block-start: 2rem;
}

.site-header {
  a {
    color: var(--text-color);
    text-decoration: none;
  }
}

.site-header-content {
  align-items: center;
  display: flex;

  > * + * {
    margin-left: 2rem;
  }

  .site-header-content__avatar {
    border-radius: 50%;
    outline: #ffffff solid 3px;
    width: 120px;
  }

  .site-header-content__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.3rem;
  }

  .site-header-content__subtitle {
    font-style: italic;
    line-height: 1.5rem;
  }
}

.site-nav {
  ul {
    list-style-type: none;
    display: flex;
    padding: 0;
  }

  ul > li + li {
    margin-left: 1rem;
  }
}

.site-footer {
  margin: 2rem 0;
  color: var(--footer-text-color);
  font-style: italic;

  .copyright {
    position: relative;
    top: 2px;
  }
}

.published-at {
  margin-block-start: 0;
  font-size: 0.85rem;
}

code.highlighter-rouge {
  padding: 0 0.125rem;
  border: 1px solid #ccccff33;
  border-radius: 4px;
  color: #ccccff;
}

pre.highlight {
  overflow-x: scroll;
}
